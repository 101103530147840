<template>
  <div>
    <div class="pb-4 bg-white flex p-2 justify-between">
      <div class="flex justify-center items-center gap-2">
        <ExcelUpload
          title="Import Risk Category"
          @file-read="handleExcelData"
        />

        <ImageButton
          :svg="require('@/assets/add.svg')"
          title="New"
          :callback="openAddRiskCategoryModal"
        />

        <!-- <ImageButton
          :svg="require('@/assets/email.svg')"
          title="Send Questionnaire"
          :callback="openSendEmailModal"
        /> -->
      </div>
    </div>
    <h1 class="text-dark-text-primary text-[60px]">
      {{ this.$route.query.name }}
    </h1>
    <AddRiskCategoryModal
      v-if="showAddModal"
      :existingAssetCategory="existingRiskCategoryData"
      @close="showAddModal = false"
      :callback="addNewAssetCategory"
    />

    <SendRiskCategoryModal
      v-if="showSendModal"
      :existingAssetCategory="existingRiskCategoryData"
      @close="showSendModal = false"
      :callback="sendEmail"
    />

    <div>
      <RiskCategoryList
        :Update="updateRiskCategory"
        :Delete="deleteRiskCategory"
        :key="riskCategoryListKey"
      />
      <RiskCategoryDetailsModal
        v-if="selectedRiskCategory"
        :riskCategory="selectedRiskCategory"
        @close="selectedRiskCategory = null"
      />
    </div>
    <div v-if="isImportModalVisible" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl">
        <TableComponent :headers="importFileHeaders" :data="importData" />
        <div class="mt-4 flex justify-end space-x-4">
          <button @click="handleCancelImport" class="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400">Cancel</button>
          <button @click="handleSubmitImport" class="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RiskCategoryList from "./asset-list.vue";
import RiskCategoryDetailsModal from "./asset-details.vue";
import AddRiskCategoryModal from "./create.vue";
import SendRiskCategoryModal from "./send-category.vue";
import http from "@/helpers/http";
import ImageButton from "@/components/reuseable/ImageButton.vue";
import ExcelUpload from "@/components/reuseable/ExcelUpload.vue";
import TableComponent from "@/components/reuseable/TableComponent.vue";
import { toast } from "vue3-toastify";

export default {
  components: {
    TableComponent,
    RiskCategoryList,
    RiskCategoryDetailsModal,
    AddRiskCategoryModal,
    SendRiskCategoryModal,
    ExcelUpload,
    ImageButton,
  },
  data() {
    return {
      selectedRiskCategory: null,
      showAddModal: false,
      showSendModal: false,
      fileUploaded: false,
      isImportModalVisible: false,
      existingRiskCategoryData: null,
      importData: null,
      excelData: null,
      riskCategoryListKey: 0,
      importFileHeaders: ["Treatment Option", "Description", "When To Use", "Companies"],
    };
  },
  methods: {
    handleCancelImport() {
      this.isImportModalVisible = false;
    },
    handleExcelData(data) {
      this.excelData = data;
      this.importData = data;
      console.log(data);
      this.fileUploaded = true;
      this.isImportModalVisible = true;
    },
    handleSubmitImport() {
      console.log(this.excelData);
      const mapped = this.excelData.map((curr) => ({
        treatmentOption: curr["Treatment Option"],
        description: curr["Description"],
        whenToUse: curr["When To Use"],
        companies: curr["Companies"]
      }));
      console.log(mapped);
      http
        .post("/api/create-bulk/risk-treatment", mapped)
        .then((response) => {
          toast.success("Risk Categories imported successfully");
          this.isImportModalVisible = false;
          console.log(response);
          this.riskCategoryListKey++;
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error importing Risk Categories");
        });
    },
    updateRiskCategory(riskCategory) {
      console.log("Updating Risk Category", riskCategory);
      this.existingRiskCategoryData = { ...riskCategory.attributes, id: riskCategory.id };
      this.showAddModal = true;
      console.log("show modal", this.showAddModal)
    },
    async deleteRiskCategory(riskCategory) {
      console.log(riskCategory);
      const res = await http.delete(`api/risk-treatments/${riskCategory.id}`);
      if (res.status == 200) {
        this.riskCategoryListKey++;
      }
    },
    openAddRiskCategoryModal() {
      this.showAddModal = true;
    },
    openSendEmailModal() {
      this.showSendModal = true;
    },
    async addNewAssetCategory(newRiskCategory) {
      console.log("Adding new Risk Category", newRiskCategory);
      if (newRiskCategory?.id) {
        const res = await http.put(`api/risk-treatments/${newRiskCategory.id}`, {
          data: newRiskCategory,
        });
        if (res.status == 200) {
          this.riskCategoryListKey++;
        }
      } else {
        const res = await http.post("api/risk-treatments", { data: newRiskCategory });
        if (res.status == 200) {
          this.riskCategoryListKey++;
        }
      }
    },
  },
};
</script>
