<template>
  <div class="container mx-auto p-6 bg-white shadow-md rounded-lg">
    <h1 class="text-2xl font-bold mb-4 text-center">Privacy Policy</h1>
    <p class="text-sm text-gray-600 text-center mb-8">
      Effective Date: 14/11/2024
    </p>

    <div class="space-y-6">
      <section>
        <h2 class="text-lg font-semibold mb-2">1. Introduction</h2>
        <p class="text-gray-700">
          At Docility, we respect and are committed to protecting the privacy of
          our clients, users, and partners. This Privacy Policy outlines how we
          collect, use, disclose, and protect information in relation to our
          software development services, and provides you with information on
          your rights regarding your personal data. We encourage you to read
          this policy to understand how we handle information in accordance with
          industry best practices and legal obligations.
        </p>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">2. Information We Collect</h2>
        <ul class="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>Personal Information:</strong> Information such as your
            name, email address, phone number, and other contact information
            provided by you or your organization.
          </li>
          <li>
            <strong>Project Information:</strong> Details related to the project
            requirements, specifications, and other relevant information
            necessary to deliver our services.
          </li>
          <li>
            <strong>Usage Information:</strong> Data about your interactions
            with our website or applications, including IP address, device
            information, and browsing behavior.
          </li>
          <li>
            <strong>Financial Information:</strong> Payment information, billing
            details, and other financial records relevant to service agreements
            and invoicing.
          </li>
        </ul>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">
          3. How We Use Your Information
        </h2>
        <ul class="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>Service Delivery:</strong> To provide, improve, and
            customize our software development services, and to manage our
            business relationship with you.
          </li>
          <li>
            <strong>Communication:</strong> To respond to inquiries, provide
            updates, and manage communications related to ongoing projects or
            support.
          </li>
          <li>
            <strong>Security and Compliance:</strong> To protect against fraud,
            unauthorized access, and other risks, and to comply with legal,
            regulatory, and contractual obligations.
          </li>
          <li>
            <strong>Billing and Payment Processing:</strong> To process
            invoices, handle billing inquiries, and maintain financial records.
          </li>
        </ul>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">4. Data Storage and Security</h2>
        <p class="text-gray-700">
          We employ a combination of administrative, technical, and physical
          safeguards to protect your information against unauthorized access,
          loss, or misuse. Information is stored on secure servers, and data may
          be transferred and processed in locations where we operate or where
          our trusted partners are located.
        </p>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">
          5. Sharing and Disclosure of Information
        </h2>
        <p class="text-gray-700">
          We do not sell or rent your personal information to third parties.
          However, we may share your data in limited scenarios:
        </p>
        <ul class="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>Service Providers:</strong> With trusted third-party service
            providers who assist us in delivering our services, under strict
            confidentiality agreements.
          </li>
          <li>
            <strong>Legal and Regulatory Requirements:</strong> To comply with
            applicable laws, regulations, or government requests, or to protect
            our rights and those of our users or clients.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of assets, where information may be transferred
            as part of the business continuity process.
          </li>
        </ul>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">6. User Rights and Choices</h2>
        <p class="text-gray-700">
          Depending on your location, you may have rights concerning your
          personal information, including:
        </p>
        <ul class="list-disc list-inside text-gray-700 space-y-1">
          <li>
            <strong>Access and Correction:</strong> The right to access and
            request correction of your personal data.
          </li>
          <li>
            <strong>Data Deletion:</strong> The right to request the deletion of
            your data, subject to legal or contractual retention requirements.
          </li>
          <li>
            <strong>Restriction and Objection:</strong> The right to restrict or
            object to certain types of data processing.
          </li>
        </ul>
        <p class="text-gray-700">
          To exercise these rights, please contact us via email.
        </p>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">7. Data Retention</h2>
        <p class="text-gray-700">
          We retain personal information only as long as necessary for the
          purposes outlined in this policy, unless a longer retention period is
          required by law or for legitimate business purposes.
        </p>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">8. Children's Privacy</h2>
        <p class="text-gray-700">
          Our services are intended for businesses and individuals of legal age,
          and we do not knowingly collect personal information from children
          under the age of 13. If we become aware of any such data, we will
          promptly delete it.
        </p>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">9. Updates to This Policy</h2>
        <p class="text-gray-700">
          We may update this Privacy Policy periodically to reflect changes in
          our practices or regulatory requirements. Any updates will be posted
          on our website, and we encourage you to review this policy
          periodically.
        </p>
      </section>

      <section>
        <h2 class="text-lg font-semibold mb-2">10. Contact Us</h2>
        <p class="text-gray-700">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy, please contact us at:
        </p>
        <ul class="text-gray-700">
          <li><strong>Privacy Officer</strong></li>
          <li><strong>Email:</strong> coming soon</li>
          <li><strong>Phone:</strong> coming soon</li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
/* Add additional styles here if needed */
</style>
